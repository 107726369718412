import * as React from 'react';
import {
    AppBar,
    Box,
    // Button,
    // ClickAwayListener,
    Container,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material'
import { Link } from 'react-scroll';
import DiamondIcon from '@mui/icons-material/Diamond';

import HeaderItem from './HeaderItem';

const sections = ['Tours', 'Questions'];

interface HeaderProps {
    items: string[]
}

export default function Header({ items }: HeaderProps) {
    const [open, setOpen] = React.useState(false);

    const toggleClick = () => {
        setOpen(!open);
      };

    const handleTooltipClose = () => {
        setOpen(false);
    };

    // const handleTooltipOpen = () => {
    //     setOpen(true);
    // };

    return (
        <AppBar
            position="fixed"
            sx={{
                boxShadow: 0,
                bgcolor: 'transparent',
                backgroundImage: 'none',
                mt: 2,
            }}
        >
            <Container maxWidth="sm">
                <Toolbar
                    variant="regular"
                    sx={(theme) => ({
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexShrink: 0,
                        borderRadius: '999px',
                        bgcolor:
                            theme.palette.mode === 'light'
                                ? 'rgba(255, 255, 255, 0.4)'
                                : 'rgba(0, 0, 0, 0.4)',
                        backdropFilter: 'blur(24px)',
                        maxHeight: 40,
                        border: '1px solid',
                        borderColor: 'divider',
                        boxShadow:
                            theme.palette.mode === 'light'
                                ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                                : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
                    })}
                >
                    {/* LOGO BOX */}
                    {/* Dont hardcode this */}
                    <Link
                        to='Hero'
                        spy={true}
                        smooth={true}
                        duration={500}
                    >
                        <Box
                            sx={{
                                cursor: 'pointer',
                                flexGrow: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                px: 0,
                            }}
                        >
                            <DiamondIcon
                                inheritViewBox={true}
                                color='primary'
                                sx={{
                                    mr: 0.5,
                                }}
                            />
                            <Typography
                                variant="h6"
                                color="primary"
                                sx={{
                                    //     // display: { xs: 'none', md: 'flex' },
                                    //     fontFamily: 'monospace',
                                    fontWeight: 'bold',
                                    wordSpacing: '-.1ch',
                                    //     // letterSpacing: '.01rem',
                                    userSelect: 'none',
                                }}
                            >
                                Hidden Gem Tours
                            </Typography>
                        </Box>
                    </Link>

                    {/* SELECTION BOX */}
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            px: 0,
                        }}
                    >
                        {sections.map((section) => (
                            <HeaderItem title={section} />
                        ))}
                        {/* <ClickAwayListener onClickAway={handleTooltipClose}> */}
                                <Tooltip
                                    arrow
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={open}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    leaveTouchDelay={10}
                                    title="info@nyhiddengem.com"
                                >
                                    <MenuItem onClick={toggleClick} sx={{ py: '6px', px: '12px' }} >
                                        {/* <Button onClick={handleTooltipOpen} /> */}
                                        <Typography variant="body2" color="text.primary">
                                            Contact
                                        </Typography>
                                    </MenuItem>
                                </Tooltip>
                        {/* </ClickAwayListener> */}
                    </Box>
                </ Toolbar>
            </ Container>
        </AppBar>
    )
}