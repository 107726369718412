import * as React from 'react';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
// import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
// import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';
import { Element } from 'react-scroll';
import { Link as RSLink } from 'react-scroll';
// import { visuallyHidden } from '@mui/utils';

// TODO: add tooltips for coming soon impl
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/X';
import DiamondIcon from '@mui/icons-material/Diamond';

// import SitemarkIcon from './SitemarkIcon';

// TODO: get a copyright
// function Copyright() {
//   return (
//     <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
//       {'Copyright © '}
//       {new Date().getFullYear()}
//     </Typography>
//   );
// }

interface FooterProps {
  linkKey: string;
}

export default function Footer({ linkKey }: FooterProps) {
  const [open, setOpen] = React.useState(false);

  const toggleClick = () => {
    setOpen(!open);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  // const handleTooltipOpen = () => {
  //   setOpen(true);
  // };

  return (
    <Element name={linkKey}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 4, sm: 8 },
          pt: { xs: 8, sm: 4 },
          pb: { xs: 8, sm: 2 },
          textAlign: { sm: 'center', md: 'left' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              minWidth: { xs: '100%', sm: '60%' },
            }}
          >

            {/* LOGO BOX */}
            <RSLink
              to='Hero'
              spy={true}
              smooth={true}
              duration={500}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  px: 0,
                  cursor: 'pointer'
                }}
              >
                <DiamondIcon
                  inheritViewBox={true}
                  color='primary'
                  sx={{
                    mr: 0.5,
                  }}
                />
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{
                    fontWeight: 'bold',
                    wordSpacing: '-.1ch',
                    userSelect: 'none',
                  }}
                >
                  Hidden Gem Tours
                </Typography>

              </Box>
            </RSLink>


            {/* NEWSLETTER */}
            {/* <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
              <DiamondIcon />
              <Typography variant="body2" gutterBottom sx={{ fontWeight: 600, mt: 2 }}>
                Join the newsletter
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                Subscribe for weekly updates. No spams ever!
              </Typography>
              <Stack direction="row" spacing={1} useFlexGap>
                <InputLabel htmlFor="email-newsletter" sx={visuallyHidden}>
                  Email
                </InputLabel>
                <TextField
                  id="email-newsletter"
                  hiddenLabel
                  size="small"
                  variant="outlined"
                  fullWidth
                  aria-label="Enter your email address"
                  placeholder="Your email address"
                // slotProps={{
                //   htmlInput: {
                //     autoComplete: 'off',
                //     'aria-label': 'Enter your email address',
                //   },
                // }}
                />
                <Button variant="contained" color="primary" sx={{ flexShrink: 0 }}>
                  Subscribe
                </Button>
              </Stack>
            </Box> */}
          </Box>
          {/* <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
              Product
            </Typography>
            <Link color="text.secondary" variant="body2" href="#">
              Features
            </Link>
            <Link color="text.secondary" variant="body2" href="#">
              Testimonials
            </Link>
            <Link color="text.secondary" variant="body2" href="#">
              Highlights
            </Link>
            <Link color="text.secondary" variant="body2" href="#">
              Pricing
            </Link>
            <Link color="text.secondary" variant="body2" href="#">
              FAQs
            </Link>
          </Box> */}
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
              Company
            </Typography>
            {/* <Link color="text.secondary" variant="body2" href="#">
              About us
            </Link> */}
            <Tooltip
              arrow
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              leaveDelay={300}
              title="info@nyhiddengem.com"
            >
              {/* <Button onClick={handleTooltipOpen} /> */}
              <Link onClick={toggleClick}
                color="text.secondary"
                variant="body2"
                sx={{ cursor: 'pointer' }}
              >
                Contact
              </Link>
            </Tooltip>
            {/* <Link color="text.secondary" variant="body2" href="#">
              Press
            </Link> */}
          </Box>
          {/* <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
              Legal
            </Typography>
            <Link color="text.secondary" variant="body2" href="#">
              Terms
            </Link>
            <Link color="text.secondary" variant="body2" href="#">
              Privacy
            </Link>
            <Link color="text.secondary" variant="body2" href="#">
              Contact
            </Link>
          </Box> */}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pt: { sm: 2 },
            width: '100%',
            borderTop: '1px solid',
            borderColor: 'divider',
          }}
        >
          {/* <div>
            <Link color="text.secondary" variant="body2" href="#">
              Privacy Policy
            </Link>
            <Typography sx={{ display: 'inline', mx: 0.5, opacity: 0.5 }}>
              &nbsp;•&nbsp;
            </Typography>
            <Link color="text.secondary" variant="body2" href="#">
              Terms of Service
            </Link>
            <Copyright />
          </div> */}
          <Stack
            direction="row"
            spacing={1}
            useFlexGap
            sx={{ justifyContent: 'left', color: 'text.secondary' }}
          >
            <Tooltip title="Coming Soon">
              <IconButton
                color="inherit"
                // href="https://github.com/mui"
                aria-label="GitHub"
                sx={{ alignSelf: 'center' }}
              >
                <FacebookIcon />
              </IconButton>
            </ Tooltip>

            <Tooltip title="Coming Soon">
              <IconButton
                color="inherit"
                // href="https://x.com/MaterialUI"
                aria-label="X"
                sx={{ alignSelf: 'center' }}
              >
                <TwitterIcon />
              </IconButton>
            </ Tooltip>

            <Tooltip title="Coming Soon">

              <IconButton
                color="inherit"
                // href="https://www.linkedin.com/company/mui/"
                aria-label="LinkedIn"
                sx={{ alignSelf: 'center' }}
              >
                <InstagramIcon />
              </IconButton>
            </Tooltip>

          </Stack>
        </Box>
      </Container>
    </ Element>

  );
}
