import * as React from 'react'
import { MenuItem, Typography } from '@mui/material'
import { Link } from 'react-scroll';

interface HeaderProps {
    title: string
}

export default function HeaderItem({ title }: HeaderProps) {
    return (
        <MenuItem sx={{ py: '6px', px: '12px' }} >
            <Link
                to={title}
                spy={true}
                smooth={true}
                duration={500} >
                <Typography variant="body2" color="text.primary">
                    {title}
                </Typography>
            </Link>
        </MenuItem>
    )
}