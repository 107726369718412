import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
// import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import MuseumIcon from '@mui/icons-material/Museum';
import ParkIcon from '@mui/icons-material/Park';
import { Element } from 'react-scroll';
// import stockPhoto from '../free-stock-photo.jpg';

// import FeatureSelectorGrid from './FeatureSelectorGrid';
// import { CardMedia } from '@mui/material';

// 'url(https://mui.com/static/images/templates/templates-images/devices-light.png)'
const items = [
  {
    icon: <ParkIcon />,
    title: 'Central Park',
    description:
      'Experience New York\'s most iconic park and hear the hidden stories it has to offer from our guides.',
    imageLight: 'Central Park ',
    imageDark: 'src/free-stock-photo.jpg',
  },
  {
    icon: <MuseumIcon />,
    title: 'Metropolitan Museum of Art',
    description:
      'Step through one of the greatest collections of Art with one of our expert guides.',
    imageLight: 'Metropolitan Art Museum ',
    imageDark: 'url(https://mui.com/static/images/templates/templates-images/devices-light.png)',
  },
  {
    icon: <AutoGraphIcon />,
    title: 'Wall Street',
    description:
      'Learn the history and impact of one of the world\'s most powerful neighborhoods.',
    imageLight: 'Wall Street ',
    imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
  },
];

interface ToursProps {
  linkKey: string;
}

export default function Features({ linkKey }: ToursProps) {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index: number) => {
    setSelectedItemIndex(index);
  };

  // const selectedFeature = items[selectedItemIndex];

  return (
    // <Element name='Tours'>
    <Element name={linkKey}>
      <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>

            {/* Feature Text */}
            <Typography component="h2" variant="h4" color="text.primary">
              Featured Tours
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              See our selected curated tours below. Our fully-licensed tour guides are New York locals and have decades of experience sharing their beloved city through their own eyes.
              We'll guide you though some of New York's most popular destinations with some lesser-known insights! 
            </Typography>
            <Typography variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}>
            If you have a particular interest in the city and would 
            like a custom-itenerary tour please contact us and we'll fit an afternoon to your liking!
            </Typography>

            {/* ******** */}
            {/* ???? */}

            {/* <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
              {items.map(({ title }, index) => (
                <Chip
                  key={index}
                  label={title}
                  onClick={() => handleItemClick(index)}
                  sx={{
                    borderColor: (theme) => {
                      if (theme.palette.mode === 'light') {
                        return selectedItemIndex === index ? 'primary.light' : '';
                      }
                      return selectedItemIndex === index ? 'primary.light' : '';
                    },
                    background: (theme) => {
                      if (theme.palette.mode === 'light') {
                        return selectedItemIndex === index ? 'none' : '';
                      }
                      return selectedItemIndex === index ? 'none' : '';
                    },
                    backgroundColor: selectedItemIndex === index ? 'primary.main' : '',
                    '& .MuiChip-label': {
                      color: selectedItemIndex === index ? '#fff' : '',
                    },
                  }}
                />
              ))}
            </Grid> */}

            {/* ???? */}

            {/* <Box
              component={Card}
              variant="outlined"
              sx={{
                display: { xs: 'auto', sm: 'none' },
                mt: 4,
              }}
            >
              <Box
                sx={{
                  backgroundImage: (theme) =>
                    theme.palette.mode === 'light'
                      ? items[selectedItemIndex].imageLight
                      : items[selectedItemIndex].imageDark,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  minHeight: 280,
                }}
              />
              <Box sx={{ px: 2, pb: 2 }}>
                <Typography color="text.primary" variant="body2" fontWeight="bold">
                  {selectedFeature.title}
                </Typography>
                <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                  {selectedFeature.description}
                </Typography>
                <Link
                  color="primary"
                  variant="body2"
                  fontWeight="bold"
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    '& > svg': { transition: '0.2s' },
                    '&:hover > svg': { transform: 'translateX(2px)' },
                  }}
                >
                  <span>Learn more</span>
                  <ChevronRightRoundedIcon
                    fontSize="small"
                    sx={{ mt: '1px', ml: '2px' }}
                  />
                </Link>
              </Box>
            </Box> */}

            {/* ************* */}
            {/* Selector Grid */}
            {/* ************* */}

            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
              useFlexGap
              sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
            >
              {items.map(({ icon, title, description }, index) => (
                <Card
                  key={index}
                  variant="outlined"
                  component={Button}
                  onClick={() => handleItemClick(index)}
                  sx={{
                    p: 3,
                    height: 'fit-content',
                    width: '100%',
                    background: 'none',
                    backgroundColor:
                      selectedItemIndex === index ? 'action.selected' : undefined,
                    borderColor: (theme) => {
                      if (theme.palette.mode === 'light') {
                        return selectedItemIndex === index
                          ? 'primary.light'
                          : 'grey.200';
                      }
                      return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      textAlign: 'left',
                      flexDirection: { xs: 'column', md: 'row' },
                      alignItems: { md: 'center' },
                      gap: 2.5,
                    }}
                  >
                    <Box
                      sx={{
                        color: (theme) => {
                          if (theme.palette.mode === 'light') {
                            return selectedItemIndex === index
                              ? 'primary.main'
                              : 'grey.300';
                          }
                          return selectedItemIndex === index
                            ? 'primary.main'
                            : 'grey.700';
                        },
                      }}
                    >
                      {icon}
                    </Box>
                    <Box sx={{ textTransform: 'none' }}>
                      <Typography
                        color="text.primary"
                        variant="body2"
                        fontWeight="bold"
                      >
                        {title}
                      </Typography>
                      <Typography
                        color="text.secondary"
                        variant="body2"
                        sx={{ my: 0.5 }}
                      >
                        {description}
                      </Typography>
                      {/* <Link
                        color="primary"
                        variant="body2"
                        fontWeight="bold"
                        sx={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          '& > svg': { transition: '0.2s' },
                          '&:hover > svg': { transform: 'translateX(2px)' },
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <span>Learn more</span>
                        <ChevronRightRoundedIcon
                          fontSize="small"
                          sx={{ mt: '1px', ml: '2px' }}
                        />
                      </Link> */}
                    </Box>
                  </Box>
                </Card>
              ))}
            </Stack>
          </Grid>

          {/* Card on the right */}

          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
          >
            <Card
              variant="outlined"
              sx={{
                height: '100%',
                width: '100%',
                display: { xs: 'none', sm: 'flex' },
                pointerEvents: 'none',
                justifyContent: 'center',
              }}
            >
              <Box
                // sx={{
                //   m: 'auto',
                //   width: 420,
                //   height: 500,
                //   backgroundSize: 'contain',
                //   backgroundImage: (theme) =>
                //     theme.palette.mode === 'light'
                //       ? items[selectedItemIndex].imageLight
                //       : items[selectedItemIndex].imageDark,
                // }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '3em',
                  textAlign: 'center'
                }}
              >
                <Typography
                // sx={{
                //   display: 'flex',
                //   justifyContent: 'center'
                // }}
                variant='h5'
                // style={{ wordWrap: 'break-word' }}
                >
                {items[selectedItemIndex].imageLight} 
                Online Booking Coming Soon!
                </Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Element>

  );
}